import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import {useDataLayer, pushJobAlertRemovedEvent} from "dataLayerHelper";
import SubMenu from "@Components/SubMenu";
import Form from 'form';
import axios from 'axios';
import FormContext from "formContext";
import Icon from "@Components/Icon"
import SectionHeader from "@Components/SectionHeader";
import DashboardItem from "@Components/DashboardItem";
import JobAlertList from "@Components/JobAlertList";
import TextWithLink from "@Components/TextWithLink";
import Modal from "@Components/Modal";
import ConfirmationModal from "@Components/ConfirmationModal";
import Button from "@FormElements/Button";
import Logger from "@ffw/logger-lib";
import { Buffer } from 'buffer';

function ManageEmailAlerts({intl, staticContext, location, match}) {
  const {locale} = intl;
  const {isAuthenticated, formData, handleErrors, currentStep, handleChange, setAdditionalData,
    additionalData, modalOpen, formName, setModalOpen, setFormData,
    setEditedItemKey, setErrors, triggerRerender, timesRendered, getFormSchema,
    setFormName} = useContext(FormContext);
  const {jobAlerts} = additionalData;
  const [showConfirmation, setConfirmation] = useState(false);
  const urlParams = new URLSearchParams(location.search);
  const isBase64 = (str) => {
    const base64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64Regex.test(str);
  };
  let email = urlParams.get('email');
  if (email) {
    const decodedEmail = decodeURIComponent(email);
    email = isBase64(decodedEmail) ? Buffer.from(decodedEmail, 'base64').toString() : decodedEmail;
  }
  let uuid = urlParams.get('uuid');
  if (!jobAlerts) {
    email = null;
    uuid = null;
  }
  const parametersProvided = (email && uuid);

  let headerTitleLeft;
  if (parametersProvided) {
    headerTitleLeft = intl.formatMessage({id: 'ManageEmailAlerts.Header.ProvidedData.Title.Left'});
  }
  else {
    headerTitleLeft = intl.formatMessage({id: 'ManageEmailAlerts.Header.Title.Left'});
  }


  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    if (parametersProvided) {
      if (window.__ROUTE_DATA__) {
        delete window.__ROUTE_DATA__;
      } else {
        axios.get(`${process.env.REACT_APP_API_PREFIX}/job-alerts/?email=${encodeURIComponent(email)}&uuid=${uuid}`)
          .then(response => {
            setAdditionalData(prevState => ({
              ...prevState,
              ...{"jobAlerts": response.data}
            }));

          }).catch(error => {
            Logger.error(error, "manage-email-alerts");
          });
      }
    }
  }, [timesRendered]);

  const handleSubmit = event => {
    event.preventDefault();
    setErrors();
    axios.post(`${process.env.REACT_APP_API_PREFIX}/job-alerts/send-unsubscribe-link`, formData[currentStep])
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setConfirmation(true);
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  };

  const renderConfirmation = () => {
    if (!showConfirmation) {
      return '';
    }
    return (
      <div
        className={`block notice-confirmed closable bg-variant-brand-quaternary notification-type-${showConfirmation}`}
        data-rs-hours-confirmed-notification-1=""
      >
        <div className="wrapper notice-confirmed__wrapper">
          <div className="notice-confirmed__media" data-rs-closable-fadeout="">
            <img src='/myrandstad-app/assets/image/icons/illustration-hand-1a.svg' alt=""/>
          </div>
          <div className="notice-confirmed__content">
            <div className="notice-confirmed__text" data-rs-closable-fadeout="">
              <p>{intl.formatMessage({id: 'ManageEmailAlerts.Confirmation.Text'})}</p>
            </div>
            <div className="notice-confirmed__close">
              <button className="button--icon-only" onClick={() => setConfirmation(false)} aria-label={intl.formatMessage({ id: "Close" })}>
                <Icon type="close-30" className="icon icon--inline"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  };

  const renderMissingJobAlerts = () => {
    const settings = {
      text: 'JobAlerts.NoAlerts.Text',
      link: 'jobs',
      linkText: 'ManageEmailAlerts.NoResults.Button.Text',
      linkClasses: 'button button--m',
      divClasses: 'my-randstad__content',
      pClasses: 'text--alternative',
      isExternal: true
    }

    return <TextWithLink settings={settings}/>;
  };

  const handleDeleteJobAlert = (index) => {
    let jobId = '';
    const item = jobAlerts.alerts[index];
    if (item) {
      jobId = item.key;
    }
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/job-alerts/${jobId}?email=${encodeURIComponent(email)}&uuid=${uuid}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          pushJobAlertRemovedEvent();
          setModalOpen(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          triggerRerender();
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  }

  const renderModal = () => {
    if (!modalOpen) {
      return '';
    }

    if (!formName) {
      const deletedItemIndex = formData[currentStep]['index'];
      const title = formData[currentStep]['searchName'] || '';
      let text = intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Text'}, {title: `'${title}'`});
      if (deletedItemIndex === null) {
        text = intl.formatMessage({id: 'ManageEmailAlerts.DeleteAll.Modal.Text'});
      }
      return (
        <ConfirmationModal
          title={intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Title'})}
          buttonFilled={false}
          handleConfirmation={() => handleDeleteJobAlert(deletedItemIndex)}
          footerButtonText={intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Footer.Button.Text'})}
        >
          <p>{text}</p>
        </ConfirmationModal>
      );
    }

    return (
      <Modal
        title={intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form name={formName} handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit}/>
      </Modal>
    );
  };

  const modalFooter = () => (
    <Button onClick={onCloseModal} className="button button--m button--filled button--full-width" settings={{text: getFormSchema(formName)[currentStep].modalButton}} />
  );

  const onCloseModal = () => {
    setModalOpen(false);
    setFormName('');
    setFormData([]);
    setEditedItemKey('');
  };

  const renderContent = () => {
    if (!parametersProvided) {
      return (
        <Form name='manage-email-alerts' handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit} hideGeneralErrors={true}/>
      );
    }
    else {
      return (
        <DashboardItem blockWrapper={true} stacked={false} bg={jobAlerts && jobAlerts.count ? "bg-variant-white block--s" : "bg-variant-brand-tertiary block--s"} spacing={false}>
          <SectionHeader title={intl.formatMessage({id: 'JobAlerts.Section.Title'})}/>
          <div className={`block__content block__content--s ${jobAlerts && jobAlerts.count ? 'block__content--align-right' : ''}`}>
            {jobAlerts && jobAlerts.count ?
              <JobAlertList jobAlerts={jobAlerts} email={email} uuid={uuid}/> : renderMissingJobAlerts()}
          </div>
        </DashboardItem>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Breadcrumb.ManageEmailAlerts'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className={`${isAuthenticated ? 'header bg-variant-white header--s header--welcome' : 'header header--text bg-variant-brand-secondary'}`}/>
      </Helmet>
      {isAuthenticated ? (
        <>
          <SubMenu skipBreadcrumbs={true} />
          <div className="block--s">
            <div className="block__wrapper block__wrapper--stacked wrapper">
              <div className="my-environment-container">
                <h1 className="content-block__title">{headerTitleLeft}</h1>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="header header--text bg-variant-brand-secondary">
          <div className="header__wrapper wrapper">
            <SubMenu/>
            <div className="header__content header__content--l content-block header__content--full-width has-breadcrumbs">
              <h1 className="content-block__title">
                <span className="content-block__title-top">{headerTitleLeft}</span>
              </h1>
            </div>
          </div>
        </div>
      )}
      {renderConfirmation()}
      {renderContent()}
      {renderModal()}
    </>
  )
}

export default injectIntl(ManageEmailAlerts);
